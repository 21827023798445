const CLARO_RESIDENTIAL_TV = 7
const CLARO_RESIDENTIAL_FIXO = 9
const CLARO_RESIDENTIAL_BANDA_LARGA = 8
const CLARO_PRE_PAGO = 2
const CLARO_CONTROLE_FACIL = 4
const TIM_PRE_PAGO = 10
const TIM_CONTROLE_EXPRESS = 11
const MCAFEE_DIGITAL_PROTECTION = 12

export default {
  CLARO_RESIDENTIAL_BANDA_LARGA,
  CLARO_RESIDENTIAL_FIXO,
  CLARO_RESIDENTIAL_TV,
  CLARO_PRE_PAGO,
  CLARO_CONTROLE_FACIL,
  TIM_PRE_PAGO,
  TIM_CONTROLE_EXPRESS,
  MCAFEE_DIGITAL_PROTECTION
}
