import ProductTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants"

export default Object.freeze({
    api: {
        url: process.env.VUE_APP_TRADE_HUB_API_URL,
        secret: process.env.VUE_APP_TRADE_HUB_API_SECRET,
        key: process.env.VUE_APP_TRADE_HUB_API_KEY,

        seller: {
            login: process.env.VUE_APP_TRADE_HUB_SELLER_LOGIN,
            password: process.env.VUE_APP_TRADE_HUB_SELLER_PASSWORD,
        },
        pointOfSale: {
            id: process.env.VUE_APP_TRADE_HUB_POINT_OF_SALE_ID
        }
    },
    productTypeOption: {
        [ProductTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION]: process.env.VUE_APP_TRADE_HUB_MCAFEE_DIGITAL_PROTECTION_PRODUCT_TYPE_ID,
        [ProductTypesOptionsConstants.CLARO_RESIDENTIAL_TV]: process.env.VUE_APP_TRADE_HUB_CLARO_RESIDENTIAL_TV_PRODUCT_TYPE_ID,
        [ProductTypesOptionsConstants.CLARO_RESIDENTIAL_BANDA_LARGA]: process.env.VUE_APP_TRADE_HUB_CLARO_RESIDENTIAL_BANDA_LARGA_PRODUCT_TYPE_ID,
    },
    partner: {
        tradeAppOne: {
            analyticTag: process.env.VUE_APP_TRADE_HUB_TRADE_APP_ONE_PARTNER_ANALYTIC_TRACKING_TAG,
            availableProducts: {
                [ProductTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION]: JSON.parse(process.env.VUE_APP_TRADE_HUB_TRADE_APP_ONE_PARTNER_MCAFEE_AVAILABLE_PRODUCTS),
                [ProductTypesOptionsConstants.CLARO_RESIDENTIAL_TV]: JSON.parse(process.env.VUE_APP_TRADE_HUB_TRADE_APP_ONE_PARTNER_CLARO_RESIDENTIAL_TV_AVAILABLE_PRODUCTS),
                [ProductTypesOptionsConstants.CLARO_RESIDENTIAL_BANDA_LARGA]: JSON.parse(process.env.VUE_APP_TRADE_HUB_TRADE_APP_ONE_PARTNER_CLARO_RESIDENTIAL_BANDA_LARGA_AVAILABLE_PRODUCTS),
            },
        },
        mcafee: {
            analyticTag: process.env.VUE_APP_TRADE_HUB_MCAFEE_PARTNER_ANALYTIC_TRACKING_TAG,
            availableProducts: {
                [ProductTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION]: JSON.parse(process.env.VUE_APP_TRADE_HUB_MCAFEE_PARTNER_MCAFEE_AVAILABLE_PRODUCTS),
            },
        },
        mcafeeRigstar: {
            analyticTag: process.env.VUE_APP_TRADE_HUB_RIGSTAR_MCAFEE_PARTNER_ANALYTIC_TRACKING_TAG,
            availableProducts: {
                [ProductTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION]: JSON.parse(process.env.VUE_APP_TRADE_HUB_RIGSTAR_MCAFEE_PARTNER_MCAFEE_AVAILABLE_PRODUCTS),
            },
        },
        mcafeeHp: {
            analyticTag: process.env.VUE_APP_TRADE_HUB_HP_MCAFEE_PARTNER_ANALYTIC_TRACKING_TAG,
            availableProducts: {
                [ProductTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION]: JSON.parse(process.env.VUE_APP_TRADE_HUB_HP_MCAFEE_PARTNER_MCAFEE_AVAILABLE_PRODUCTS),
            },
        },
    },
    mcafee:{
        darkWeb:{
            term: process.env.VUE_APP_LINK_TERM_MCAFEE_DARK_WEB,
            privacy: process.env.VUE_APP_LINK_PRIVACY_MCAFEE_DARK_WEB 
        }
    }
})