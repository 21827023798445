const TRADE_UP_GROUP = 'tradeupgroup'
const PERNAMBUCANAS = 'pernambucanas'
const HPANDMCAFEE = 'hpandmcafee'
const HPMCAFEE = 'hpmcafee'
const MCAFEE = 'mcafee'
const RIGSTAR = 'rigstar'
const CARREFOUR = 'carrefour'

export default {
    TRADE_UP_GROUP,
    PERNAMBUCANAS,
    HPANDMCAFEE,
    HPMCAFEE,
    MCAFEE,
    RIGSTAR,
    CARREFOUR
}
